import React from 'react';
import LanguageSelectionImage from '../img/case-studies/cw-max-languageSelection.jpg';
import maxLogoImage from '../img/case-studies/MaxLogo_white.svg';
import maxShoppingUserFlowImage from '../img/case-studies/cw-max-shoppingFlowchart.jpg';
import maxDesignProcessImage from '../img/case-studies/cw-max-designProcess.jpg';
import maxCommissionReportImage from '../img/case-studies/cw-max-commissionReport.jpg';
import maxCommissionReportImage2 from '../img/case-studies/cw-max-commissionReport2.jpg';

function CaseStudy3() {
  const projectComponents = [
    { title: "The Max Website", description: "Max's primary hub for information and product sales" },
    { title: "The Virtual Office", description: "Online portal for Max Associates to manage their business" },
    { title: "The Max App", description: "A prospecting and contact management tool for Max Associates" }
  ];

  return (
    <section id="caseStudy_max" className="bg-light">
      <div className="bannerArea">
        <div className="bannerContent">
          <img alt="Max Logo" src={maxLogoImage} />
        </div>
      </div>
      <div className="container">
        <div className="row py-5 px-4">
          <div className="col">
            <h2>About</h2>
            <p>
              Max International specializes in glutathione-enhancing products, emphasizing health and wellness. Through a network marketing model, independent Associates distribute Max's products and drive business growth, earning income based on sales within their teams
            </p>
            <h2>The Challenge</h2>
            <p>
              With a global presence, Max International's digital ecosystem needed to operate seamlessly across numerous countries and languages while accounting for varying product availability across markets. I was tasked with redesigning their digital platforms to improve user experiences and facilitate product sales. The complexity came from understanding how different user types interacted with Max's various digital touchpoints, from first time customers to experienced Associates.
            </p>
            <p>
              Success required a deep understanding of how each platform served Max's business model. Each component of the digital ecosystem played a distinct but interconnected role: attracting new customers and Associates, facilitating sales, and providing business management tools.
            </p>
          </div>
          <div className="col-12 col-md-3">
            <section id="projectComponents" className=" pt-5 pt-md-0 px-0 px-md-4">
            <div>
              <h3>Project Components:</h3>
              <ul>
                <li>The Company Website</li>
                <li>The Virtual Office</li>
                <li>Max Mobile App</li>
              </ul>
            </div>
            <h3>My Role</h3>
            <ul>
              <li>Research and analysis</li>
              <li>User Flows</li>
              <li>App Design</li>
                <li>Art Direction</li>
                <li>Prototyping</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="process-timeline">
              {projectComponents.map((component, index) => (
                <div key={index} className="process-step">
                  <div className="step-number">{index + 1}</div>
                  <h3>{component.title}</h3>
                  <p>{component.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container ">
        <div className="row py-5 px-4 bg-white--rounded">
          <div className="col-12 col-md-6"> 
            <h2>The Process</h2>
            <h3>Research and Analysis</h3>
            <p>
              Our process began with comprehensive research of Max's digital ecosystem through user interviews, competitor analysis, and system audits. This research revealed key challenges around product purchasing workflows and global market requirements.
            </p>
            <p>
              We documented existing user flows and pain points for each identified challenge. Through research of competitor solutions and industry best practices, we created potential user flows to address these challenges.
            </p>
            <p>
              A notable example was the complex product purchase flow. The system required separate paths for new and existing users, which complicated the customer journey. The process needed to properly attribute sales to Associates for commission tracking while remaining user-friendly.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img className="mt-5 my-md-0" alt="Max Shopping User Flow" src={maxShoppingUserFlowImage} />
          </div>
        </div>
        <div className="row py-5 px-4">
          <div className="col-12">
            <h3>Design and Prototyping</h3>
            <p>
              I then explored solutions through thumbnail sketches and low-fidelity prototypes. After testing and validation, I created high-fidelity prototypes to guide the development team. This approach was applied across all product components in the Max ecosystem.
            </p>
            <img className="img-rounded my-5" alt="The design process featuring thumbnail sketches and prototypes" src={maxDesignProcessImage} />
          </div>
        </div>
        <div className="row pb-5 px-4">
          <div className="col-12 col-md-5">
            <img className="img-rounded my-5 my-md-0" alt="Example of a language selection page" src={LanguageSelectionImage} />
          </div>
          <div className="col-12 col-md-7 pb-5">
            <h3>Adapting to Global Markets</h3>
            <p>
              Max International's global presence required solutions that could adapt to diverse user needs across countries, languages, and account types. Associates needed access to business tools like events and commission reports, while customers focused primarily on product information and purchases.
            </p>
            <p>
              Different markets also meant varying product availability, requiring a flexible content management system. To address these challenges, we implemented:
            </p>
            <ul>
              <li>A <strong>modular content management system</strong> that adapts to regional requirements</li>
              <li>A <strong>streamlined enrollment flow</strong> that balances sponsor ID requirements with user convenience</li>
              <li>Market-specific landing pages with <strong>language selection</strong></li>
            </ul>
          </div>
        </div>
        <div className="row pb-5 px-4">
          <div className="col-12 col-md-6">
            <h2>The Virtual Office</h2>
            <p>
              Building on our user research, we reorganized the Virtual Office to better serve Associates' daily business needs. Key improvements included:
            </p>
            <ul>
              <li><strong>Simplified navigation:</strong> Consolidated menu items for intuitive information access</li>
              <li><strong>Enhanced reporting:</strong> Redesigned reports with clear business context and actionable insights</li>
              <li><strong>Structured training:</strong> Modular courses with clear progression paths and focused topics</li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <div id="commissionReportImgContainer" className="size--3x2">
            <img className="commissionReportImg2 img-rounded" alt="Commission Report Example" src={maxCommissionReportImage2} />
              <img className="commissionReportImg img-rounded" alt="Commission Report Example" src={maxCommissionReportImage} />
            </div>
          </div>
        </div>
      </div>
      </section>
      <div className="highlightSection">
        <div className="container">
          <div className="row py-5 px-4">
            <div className="col-12 col-lg-5 bg-white--rounded py-4 px-0 px-lg-4">
              <h2>The Max App</h2>
              <p>
                The Max App redesign focused on empowering Associates in the field with essential business tools:
              </p>
              <ul>
                <li>One-tap <strong>content sharing</strong> for customer presentations</li>
                <li>Prospect tracking with automated <strong>follow-up reminders</strong></li>
                <li>Quick access to <strong>training and sales resources</strong></li>
              </ul>
              <p className="text-center pt-3">
                <a class="button-secondary" href="https://xd.adobe.com/view/3027491b-1af1-412a-beeb-2334fc5a5da4-6574/" target="_blank">View the Prototype</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-5 px-4">
          <div className="col-12">
            <h2>Reflections and Learnings</h2>
            <p>
              This project provided valuable insights into designing for complex, multi-user systems. Through the process, I:
            </p>
            <ul>
              <li>Created <strong>comprehensive user flows</strong> that addressed the needs of both Associates and customers, demonstrating how different user types could coexist within the same ecosystem</li>
              <li>Developed solutions for complex business requirements while maintaining user-friendly interfaces, particularly in areas like sponsor attribution and <strong>multi-language support</strong></li>
              <li>Built <strong>interactive prototypes</strong> that effectively communicated design solutions and user interactions</li>
            </ul>
            <p>
              The experience highlighted the importance of cross-functional collaboration and the need for strong alignment between design and development teams. It also reinforced the value of creating detailed documentation and specifications to support successful implementation.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaseStudy3;