import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function Carousel() {
  const [currentItem, setCurrentItem] = useState(2);
  const items = [
    {
      id: 1,
      title: 'Adaptable and Accessible',
      subtitle: 'The InMoment Survey Design System',
      buttonText: 'Find out more',
    },
    {
      id: 2,
      title: 'Is it mine?',
      subtitle: 'Exploration in working with AI',
      buttonText: 'See what I\'ve found',
    },
    {
      id: 3,
      title: 'Max',
      subtitle: 'Insights from a site and app redesign',
      buttonText: 'Read the case study',
    },
  ];

  const totalItems = items.length;
  const autoSlideDelay = 7500; // Changed to 7.5 seconds
  const navigate = useNavigate();
  const intervalRef = useRef(null);

  // Set this variable to control visibility of navigation and indicators
  const showNavigationAndIndicators = false; // Change to true to show them

  useEffect(() => {
    // Only set the interval if you want to cycle through items
    // Commenting out the interval to prevent cycling
    // intervalRef.current = setInterval(() => {
    //   setCurrentItem((prevItem) => (prevItem + 1) % totalItems);
    // }, autoSlideDelay);

    return () => clearInterval(intervalRef.current);
  }, [totalItems]);

  const handleBack = () => {
    // Prevent cycling, just keep it on the "Max" item
    // setCurrentItem((prevItem) => (prevItem - 1 + totalItems) % totalItems);
  };

  const handleNext = () => {
    // Prevent cycling, just keep it on the "Max" item
    // setCurrentItem((prevItem) => (prevItem + 1) % totalItems);
  };

  const navigateToCaseStudy = (itemId) => {
    navigate(`/case-studies/${itemId}`);
  };

  return (
    <section id="carousel_section">
      <div className="content-container">
        <div className="carousel-container">
          <div className="carousel">
            {items.map((item, index) => {
              const isActive = index === currentItem;
              return (
                <div
                  key={item.id}
                  id={`carousel-item-${item.id}`}
                  className={`carousel-item ${isActive ? 'active' : ''}`}
                >
                  <div className="background-image" />
                  <div className="carousel-content">
                    <h1>{item.title}</h1>
                    <p>{item.subtitle}</p>
                    <button className="button-primary" onClick={() => navigateToCaseStudy(item.id)}>
                      {item.buttonText}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {showNavigationAndIndicators && ( // Conditional rendering
          <>
            <div className="carousel-navigation">
              <button className="back-button" onClick={handleBack}>
                arrow_back_ios_new
              </button>
              <button className="next-button" onClick={handleNext}>
                arrow_forward_ios
              </button>
            </div>
            <div className="carousel-indicators">
              {items.map((_, index) => (
                <div
                  key={index}
                  className={`indicator ${index === currentItem ? 'active' : ''}`}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Carousel;