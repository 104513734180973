// portfolioData.js
import DiamondRetreatImage from '../img/portfolio/DiamondRetreatBooklet.jpg';
import DiamondRetreatThumbnail from '../img/portfolio/DiamondRetreatBooklet_thumb.jpg';
import SchotzieImage from '../img/portfolio/Schotzie_Final.jpg';
import SchotzieThumbnail from '../img/portfolio/Schotzie_thumb.jpg';
import DoubleDownImage from '../img/portfolio/DoubleDown_final.png';
import DoubleDownThumbnail from '../img/portfolio/DoubleDown_thumb.jpg';
import CollageTileImage from '../img/portfolio/CollageTile_final.jpg';
import CollageTileThumbnail from '../img/portfolio/CollageTile_thumb.jpg';
import HannaFarmsImage from '../img/portfolio/TrailerWithDecal.jpg';
import HannaFarmsThumbnail from '../img/portfolio/TrailerWithDecal_thumb.jpg';
import MaxSiteImage from '../img/portfolio/SiteOnDevices_final.jpg';
import MaxSiteThumbnail from '../img/portfolio/SiteOnDevices_thumb.jpg';
import WaltImage from '../img/portfolio/WaltPortrait.jpg';
import WaltThumbnail from '../img/portfolio/WaltPortrait_thumb.jpg';
import MaxItImage from '../img/portfolio/maxit-stackboxes.jpg';
import MaxItThumbnail from '../img/portfolio/maxit-nfuze_thumb.jpg';
import VisibleSolutionsImage from '../img/portfolio/VS_FullFamily_final.jpg';
import VisibleSolutionsThumbnail from '../img/portfolio/VS_FullFamily_thumb.jpg';
import CelenaImage from '../img/portfolio/CelenaWinter.jpg';
import CelenaThumbnail from '../img/portfolio/CelenaWinter_thumb.jpg';
import TranscendImage from '../img/portfolio/Transcend_final.jpg';
import TranscendThumbnail from '../img/portfolio/Transcend_thumb.jpg';
import MaxAppImage from '../img/portfolio/MaxApp_final.jpg';
import MaxAppThumbnail from '../img/portfolio/MaxApp_thumb.jpg';
import SolDucFallsImage from '../img/portfolio/SolDucFalls.jpg';
import SolDucFallsThumbnail from '../img/portfolio/SolDucFalls_thumb.jpg';
import FamilyRecipeImage from '../img/portfolio/FamilyRecipe.jpg';
import FamilyRecipeThumbnail from '../img/portfolio/FamilyRecipe_thumb.jpg';
import ScreamingMicImage from '../img/portfolio/ScreamingMic.jpg';
import ScreamingMicThumbnail from '../img/portfolio/ScreamingMic_thumb.jpg';
import GreaseTrapImage from '../img/portfolio/GreaseTrap.jpg';
import GreaseTrapThumbnail from '../img/portfolio/GreaseTrap_thumb.jpg';
import InMomentImage from '../img/portfolio/InMoment.jpg';
import InMomentThumbnail from '../img/portfolio/InMoment_thumb.jpg';

export const portfolio = [
  {
    id: 'Schotzie',
    title: 'Schotzie Portrait',
    subtitle: 'Digital Painting',
    categories: ['Fun', 'Featured'],
    description: 'A digital painting I created of an old friend of mine.',
    image: SchotzieImage,
    thumbnail: SchotzieThumbnail,
  },
  {
    id: 'DiamondRetreat',
    title: 'Diamond Retreat',
    subtitle: 'Event Branding',
    categories: ['Work', 'Featured'],
    description: 'Each year, Max International celebrates its most successful Associates by hosting an exclusive retreat at a breathtaking destination. As the lead designer for the editorial pieces for this event, I was responsible for creating comprehensive event branding and developing an immersive informational booklet. This Diamond Retreat booklet for the Thailand expedition exemplifies this approach, combining cultural insight, travel guidance, and elegant design to enhance the experience for top-performing Associates.',
    image: DiamondRetreatImage,
    thumbnail: DiamondRetreatThumbnail,
  },
  {
    id: 'DoubleDown',
    title: 'Order Drop',
    subtitle: 'Event Branding',
    categories: ['Work', 'Featured'],
    description: 'This was the first graphic design project that I was ever commissioned to create. The task was to design an order drop, which is a flier that would be inserted into the shipping package of each order placed. The goal of this project was to promote both Max\'s upcoming convention, as well as introduce a new promotion where Associates could earn a trip to the Great Barrier Reef.',
    image: DoubleDownImage,
    thumbnail: DoubleDownThumbnail,
    bgSize: `contain`,
  },
  {
    id: 'CollageTile',
    title: 'Collage Tile',
    subtitle: 'UX Prototype',
    categories: ['Work', 'Featured'],
    description: 'A company that specializes in sourcing tile and flooring from various manufacturers reached out to me to design their site. For this project I created a UX prototype for them using Adobe InDesign. <br><br><a class="button-primary" href="https://xd.adobe.com/view/2fe27329-37cd-4bba-7167-5d8c8255a864-59d3/" target="_blank">View the Prototype</a>',
    image: CollageTileImage,
    thumbnail: CollageTileThumbnail,
  },
  {
    id: 'InMoment',
    title: 'InMoment',
    subtitle: 'Survey Design',
    categories: ['Work', 'Featured'],
    description: 'As a design lead at InMoment, I spearheaded the creation of tailored survey themes and email campaigns for clients worldwide. Through a collaborative process, we developed a robust survey theming solution that offered extensive flexibility. This allowed us to move beyond generic templates and instead deliver highly customized survey layouts, prompts, and interactions that seamlessly aligned with each client\'s unique brand guidelines and design standards. By prioritizing both brand alignment and user experience, our team was able to create survey solutions that reinforced visual identity and encouraged higher engagement.',
    image: InMomentImage,
    thumbnail: InMomentThumbnail,
  },
  {
    id: 'HannaFarms',
    title: 'Hanna Farms',
    subtitle: 'Vehicle Wrap',
    categories: ['Work'],
    description: 'Hanna Farms contacted me to design a wrap for their refrigerated trailer. The goal was to promote their business of selling beef raised on their farm in the Uinta Mountains, and to promote their website.',
    image: HannaFarmsImage,
    thumbnail: HannaFarmsThumbnail,
  },
  {
    id: 'GreaseTrap',
    title: 'Grease Trap',
    subtitle: 'Cover Art',
    categories: ['Fun'],
    description: '"Grease Trap" is an animated series in early development. The project merges AI-driven scene and asset generation with an intensive process of paintovers, curation, and refinement. By blending cutting-edge technology with traditional artistry, the series develops a unique creative workflow that balances AI-generated content with a personal touch, defining its distinct aesthetic.',
    image: GreaseTrapImage,
    thumbnail: GreaseTrapThumbnail,
    bgSize: `contain`,
    bgColor: `#000000`
  },
  {
    id: 'WaltPortrait',
    title: 'Walter White Portrait',
    subtitle: 'Oil Painting',
    categories: ['Fun', 'Featured'],
    description: 'This oil painting is a portrait of Walter White, the central character from the TV series "Breaking Bad." The artwork is my attempt at a representation of the actor Bryan Cranston\'s distinctive features and appearance as the character.',
    image: WaltImage,
    thumbnail: WaltThumbnail,
    bgSize: `contain`,
    bgColor: `#000000`
  },
  {
    id: 'MaxSite',
    title: 'Max Corporate Website',
    subtitle: 'UX Design',
    categories: ['Work', 'Featured'],
    description: 'I led the comprehensive redesign of Max\'s global website, catering to over 15 countries. My responsibilities included revamping the website\'s user experience, creating UX prototypes, and ensuring seamless multilingual support. I also collaborated on site development and streamlined translation processes across departments.',
    image: MaxSiteImage,
    thumbnail: MaxSiteThumbnail,
  },
  {
    id: 'MaxIt',
    title: 'Max It Convention',
    subtitle: 'Event Branding',
    categories: ['Work', 'Featured'],
    description: 'At Max International, event theming was a critical aspect of creating a cohesive visual experience. Each convention required a carefully crafted theme that could be consistently applied across multiple design touchpoints throughout the event space. These graphics showcase how I adapted the "Max It" Convention theme to promote the company\'s product lineup, ensuring visual continuity and brand recognition across various promotional materials and signage within the convention center.',
    image: MaxItImage,
    thumbnail: MaxItThumbnail,
    bgSize: `contain`,
    bgColor: `#f6f6f6`
  },
  {
    id: 'VisibleSolutions',
    title: 'Visible Solutions',
    subtitle: 'Package Design',
    categories: ['Work', 'Featured'],
    description: 'When Max International updated the formula for their Visible Solutions product line, they tasked me with refreshing the packaging to reflect these product improvements. My objective was to ensure the packaging felt like a clear upgrade from the previous design, visually communicating the enhanced formula to customers. <br><br>In this project, I handled all aspects of the packaging design, including the logo, paper stock, color, and printing techniques. The updated packaging not only elevated the visual brand but also effectively signaled the meaningful improvements made to the Visible Solutions line.',
    image: VisibleSolutionsImage,
    thumbnail: VisibleSolutionsThumbnail,
  },
  {
    id: 'Celena',
    title: 'Celena Portrait',
    subtitle: 'Digital Painting',
    categories: ['Fun'],
    description: 'A digital painting of my wife, trying to capture her beauty and personality. It was a fun challenge to translate her features into a digital portrait.',
    image: CelenaImage,
    thumbnail: CelenaThumbnail,
  },
  {
    id: 'ScreamingMic',
    title: 'ScreamingMic',
    subtitle: 'Print on Demand',
    categories: ['Fun'],
    description: 'A bold black and white illustration depicting a screaming microphone with a wide-open, tooth-filled mouth. Through this illustration, I aimed to capture the raw, untamed essence of live music, and the power and intensity that performers often bring to the stage. This is a design that I created for a print on demand service where it would be applied to a shirt, hoodie, pin or other materials.',
    image: ScreamingMicImage,
    thumbnail: ScreamingMicThumbnail,
  },
  {
    id: 'SolDucFalls',
    title: 'Sol Duc Falls',
    subtitle: 'Digital Painting',
    categories: ['Fun', 'Featured'],
    description: 'A digital painting of the Sol Duc Falls in Olympic National Park, capturing the cascading waters and lush forest landscape of this iconic Washington state waterfall.',
    image: SolDucFallsImage,
    thumbnail: SolDucFallsThumbnail,
    bgSize: `contain`,
    bgColor: `#0b0100`
  },
  {
    id: 'Transcend',
    title: 'Transcend',
    subtitle: 'Event Branding',
    categories: ['Work', 'Featured'],
    description: 'Max holds an international convention each year, each with its own unique theme. For 2016 I was in charge of designing the logo, creating graphics, and establishing visual guidelines for the theme, "Transcend." These visuals would need to be able to adapt well for video, web, and print.',
    image: TranscendImage,
    thumbnail: TranscendThumbnail,
  },
  {
    id: 'MaxApp',
    title: 'The Max App',
    subtitle: 'App Design',
    categories: ['Work', 'Featured'],
    description: 'On this project, I designed a mobile application for Max International to help Associates manage contacts, track prospective customers, and support business development. The app needed to integrate a comprehensive training system and provide intuitive tools for business growth. I created a full UX prototype demonstrating the application\'s intended features and user flow.  <br><br><a class="button-primary" href="https://xd.adobe.com/view/3027491b-1af1-412a-beeb-2334fc5a5da4-6574/" target="_blank">View the Prototype</a>',
    image: MaxAppImage,
    thumbnail: MaxAppThumbnail,
  },
  {
    id: 'FamilyRecipe',
    title: 'Family Recipe',
    subtitle: 'Print on Demand',
    categories: ['Fun'],
    description: 'This playful illustration was created as a print-on-demand design to be applied to shirts, mugs, and other materials. The image features an anthropomorphized rooster dressed in a suit and bowtie, holding a bucket of delicious fried chicken that is a cherished family recipe.',
    image: FamilyRecipeImage,
    thumbnail: FamilyRecipeThumbnail,
    bgSize: `contain`,
    bgColor: `#0b0100`
  },
];